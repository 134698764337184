/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_appsync_graphqlEndpoint": "https://x3t25e3bqfdltmfke3sr7miyvy.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-6tbjm34o3bbctiqgcokngl65my",
    "aws_cloud_logic_custom": [
        {
            "name": "locusmappingv2Api",
            "endpoint": "https://lalbh13bci.execute-api.ap-northeast-1.amazonaws.com/prd",
            "region": "ap-northeast-1"
        }
    ],
    "aws_cognito_identity_pool_id": "ap-northeast-1:f293fb20-42cd-44a2-b089-3b78e2e54cb1",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_KDSqzLwLq",
    "aws_user_pools_web_client_id": "1o2h7t8jv0s0r6a97tod2uj82a",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "locusmappingv2master224954-prd",
    "aws_user_files_s3_bucket_region": "ap-northeast-1"
};


export default awsmobile;
